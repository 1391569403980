<template>
  <v-container>
    <v-row justify="space-between" align="center">
      <v-col>
        <v-select
          dense
          style="max-width: 220px"
          v-model="selectedRole"
          :items="roles"
          item-text="pluralTitle"
          item-value="role"
          return-object
          label="Выберите пользователей"
          class="mr-3"
          hide-details
        >
        </v-select>
      </v-col>
      <v-col class="d-flex justify-end">
        <div>
          <span class="filter-btn" @click="switchToggle">
            {{ toggle ? "Скрыть фильтры" : "Показать фильтры" }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <UserTable :role="selectedRole" :filterToggle="toggle" />
    </v-row>

    <preloader v-if="loading" />
  </v-container>
</template>

<script>
import { ref, watch, inject, onMounted } from "@vue/composition-api";
import { useToggle } from "@/use/helpers";
import UserTable from "./users/UserTable";

export default {
  name: "Users",
  setup(_, { root }) {
    const store = root.$store;

    const loading = inject("loading");

    const { toggle, switchToggle } = useToggle();

    const selectedRole = ref({});

    const roles = ref(
      Object.values(store.getters.getRoles).filter(
        el => el.role !== "super_admin" && el.role !== selectedRole.role
      )
    );
    selectedRole.value = roles.value[0];

    const usersKey = ref(0);
    watch(
      () => selectedRole.value,
      val => {
        toggle.value = false;
        if (selectedRole.value) {
          roles.value.splice(
            roles.value.findIndex(el => el.role === val.role),
            1
          );
          roles.value.unshift(val);
        }
      }
    );

    onMounted(() => localStorage.setItem("lastTab", "Users"));

    return {
      loading,
      roles,
      selectedRole,
      toggle,
      switchToggle,
      usersKey,
    };
  },
  components: {
    UserTable,
  },
};
</script>
