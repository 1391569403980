<template>
  <v-container>
    <DataFilter v-if="filterToggle" />
    <infinity-collection @scrolled="fetchUsers">
      <v-data-table
        class="elevation-6"
        fixed-header
        :loading="loading"
        :headers="headers"
        :items="users"
        :custom-sort="customSort"
        v-model="selectedRows"
        disable-pagination
        hide-default-footer
        show-select
        height="450px"
        @click:row="selectRow"
      />
    </infinity-collection>

    <div class="d-flex justify-space-between mt-8">
      <div class="d-flex">
        <v-btn
          class="white--text primary mr-9"
          style="min-width: 220px"
          :disabled="!selectedRows.length"
          @click="switchToggle(true, 'edit-multiple')"
        >
          Редактировать ({{ selectedRows.length }})
        </v-btn>
        <confirm
          text="Удалить выбранные записи?"
          :btnDisabled="!selectedRows.length"
          @confirm="removeUsers"
        >
          Удалить ({{ selectedRows.length }})
        </confirm>
        <v-btn
          class="white--text primary ml-9"
          style="min-width: 220px"
          @click="downloadExcel"
        >
          Скачать
          {{ selectedRows.length ? ` (${selectedRows.length})` : " все" }}
        </v-btn>
      </div>

      <v-btn
        class="white--text primary"
        style="min-width: 235px"
        @click="switchToggle(true, 'select')"
      >
        Добавить {{ roleCase }}
      </v-btn>
    </div>

    <confirm
      :extDialog="isConfirmOpened"
      text="Для обновления собственных ролей потребуется повторный вход. Продолжить?"
      @confirm="proceed"
      @cancel="isConfirmOpened = false"
    />

    <modal :dialog="toggle" :width="500" persistent>
      <SelectCreateType
        v-if="toggle.isOpen && toggle.view === 'select'"
        :role="role"
        @selected="switchToggle(true, $event)"
      />

      <DataForm
        v-else-if="toggle.isOpen && toggle.view.startsWith('create')"
        :fields="tableHeaders[`${selectedRole}Headers`]"
        :rowData="[]"
        :role="role"
        :external="toggle.view.endsWith('External')"
        :development="toggle.view.endsWith('Development')"
        :dialog="toggle"
        @save="createUser"
      />
      <DataForm
        v-else-if="toggle.isOpen && toggle.view.startsWith('edit')"
        :fields="tableHeaders[`${selectedRole}Headers`]"
        :rowData="selectedRows[0]"
        :role="role"
        :edit="true"
        :rows="toggle.view.endsWith('multiple') ? selectedRows : null"
        :external="selectedRows[0].isExternal"
        :development="toggle.view.endsWith('Development')"
        :dialog="toggle"
        @reset-password="resetPassword"
        @save="updateUser"
      />
      <Upload v-else-if="toggle.view == 'upload'" :role="role" />
    </modal>
  </v-container>
</template>

<script>
import { ref, computed, inject, watch } from "@vue/composition-api";

import { useModalToggle, useSetFilters } from "@/use/helpers";
import useTableHeaders, { customSort } from "@/use/tableHeaders";

import DataFilter from "@/components/shared/assets/table/DataFilter";
import SelectCreateType from "@/components/shared/assets/table/SelectCreateType";
import DataForm from "./DataForm";

export default {
  props: ["role", "filterToggle"],
  setup(_, { root }) {
    const store = root.$store;

    const loading = inject("loading");

    const selectedRole = computed(() => _.role.role);
    watch(
      () => selectedRole.value,
      () => {
        selectedRows.value = [];
        fetchUsers();
      }
    );

    const tableHeaders = useTableHeaders(store);
    const headers = computed(() =>
      tableHeaders[`${selectedRole.value}Headers`].filter(el => !el.noTable)
    );

    const users = computed(() => store.getters.getUsers(selectedRole.value));

    const selectedRows = ref([]);

    const { toggle, switchToggle } = useModalToggle();
    watch(
      () => toggle.isOpen,
      val => {
        if (!val) selectedRows.value = [];
      }
    );
    const selectRow = row => {
      if (!selectedRows.value.includes(row)) selectedRows.value.push(row);
      switchToggle(true, "edit");
    };

    const isNeedConfirm = computed(
      () =>
        selectedRows.value
          .map(el => el.id)
          .indexOf(store.getters.getAuthData.id) !== -1
    );

    const fetchUsers = async () => {
      loading.value = true;
      await store.dispatch("fetchUsers", {
        role: selectedRole.value,
      });
      loading.value = false;
    };
    const createUser = async user => {
      loading.value = true;
      if (!user.roles)
        await store
          .dispatch("createUser", [user.role, user])
          .then((selectedRows.value = []));
      else await updateUser(user);
      loading.value = false;
    };
    const updateUser = async user =>
      await store.dispatch("updateUser", [user.role, user, user.id]);

    const isConfirmOpened = ref(false);

    const removeUsers = (confirmed = false) => {
      if (!confirmed && isNeedConfirm.value) {
        isConfirmOpened.value = true;
        return;
      }
      store
        .dispatch("removeUsers", {
          role: selectedRole.value,
          ids: selectedRows.value.map(el => el.id),
        })
        .then((selectedRows.value = []));
    };

    const proceed = () => {
      isConfirmOpened.value = false;
      removeUsers(true);
    };

    const roleCase = computed(
      () => store.getters.getRoles[selectedRole.value.toUpperCase()].cases[0]
    );

    const resetPassword = email => store.dispatch("resetPassword", email);

    const downloadExcel = () =>
      store.dispatch("downloadExcel", {
        target: "users",
        role: selectedRole.value,
        ids: selectedRows.value.map(el => el.id),
      });

    return {
      loading,
      headers,
      users,
      customSort,
      selectedRole,
      selectedRows,
      selectRow,
      toggle,
      switchToggle,
      fetchUsers,
      createUser,
      removeUsers,
      updateUser,
      isConfirmOpened,
      proceed,
      tableHeaders,
      resetPassword,
      roleCase,
      downloadExcel,
      ...useSetFilters(headers, users),
    };
  },
  components: {
    DataFilter,
    SelectCreateType,
    DataForm,
  },
};
</script>
