<template>
  <v-container>
    <p v-if="edit" class="primary--text text-center" style="font-weight: 500">
      Редактирование данных {{ role.cases[0] }}:
    </p>
    <p v-else class="primary--text text-center" style="font-weight: 500">
      Пожалуйста, заполните данные
      {{ role.cases[0] }}:
    </p>
    <v-row
      :justify="edit ? 'space-between' : 'center'"
      align="center"
      class="mt-5 flex-nowrap"
    >
      <confirm
        v-if="edit && rows && rows.length > 1"
        :extDialog="prevConfirm"
        text="Сохранить изменения?"
        @cancel="prevEdit(false)"
        @confirm="prevEdit(true)"
      >
        <div
          class="arrow"
          @click.stop="checkChanges() ? (prevConfirm = true) : prevEdit()"
        >
          <v-icon>mdi-arrow-left</v-icon>
          Назад
        </div>
      </confirm>
      <v-form ref="dataform" class="flex-grow-1 px-5">
        <FormField
          v-for="field in inspectedFields"
          :key="field.value"
          :model="formData"
          :options="field"
        />
        <v-checkbox
          v-model="formData.isNotifiable"
          :default="false"
          label="Уведомления включены"
          class="field-wrap mt-5"
        />
      </v-form>
      <confirm
        v-if="edit && rows && rows.length > 1"
        :extDialog="nextConfirm"
        text="Сохранить изменения?"
        @cancel="nextEdit(false)"
        @confirm="nextEdit(true)"
      >
        <div
          class="arrow"
          @click.stop="checkChanges() ? (nextConfirm = true) : nextEdit()"
        >
          Вперед
          <v-icon>mdi-arrow-right</v-icon>
        </div>
      </confirm>
    </v-row>
    <div
      :style="{
        maxWidth: '320px',
        margin: 'auto',
      }"
    >
      <confirm
        v-if="edit && !role.noPassword"
        block
        text="Сбросить пароль?"
        @confirm="resetPassword"
      >
        Сбросить пароль
      </confirm>
      <v-btn
        v-if="role.role !== 'participant'"
        block
        class="white--text primary mb-2 mt-2"
        @click="save(!(rows && rows.length > 1), true)"
        :disabled="!formData.isNotifiable"
      >
        Сохранить и отправить уведомление
      </v-btn>
      <v-btn
        block
        class="white--text primary mt-2"
        @click="save(!(rows && rows.length > 1), false)"
      >
        {{
          role.role === "participant"
            ? "Сохранить"
            : "Сохранить без уведомления"
        }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import {
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
} from "@vue/composition-api";
import { useFormValidation } from "@/use/form";
import FormField from "@/components/shared/assets/table/FormField";

export default {
  props: [
    "fields",
    "rowData",
    "role",
    "edit",
    "external",
    "development",
    "dialog",
    "rows",
  ],
  setup(_, { refs, emit, root }) {
    const store = root.$store;

    const registratedUser = computed(() => store.getters.getCurrentUser);
    watch(registratedUser, val => {
      val.createType = formData.value.createType;
      val.fullName = formData.value.fullName;
      val.newRole = _.role.role;
      formData.value = val;
    });

    onMounted(async () => {
      if (!_.edit && _.role.role) {
        await store.dispatch("fetchAllShortUsers", { notRole: _.role.role });
      }
    });
    onUnmounted(() => {
      store.commit("clearAllShortUsers");
      store.commit("setCurrentUser", {});
    });

    const inspectedFields = computed(() =>
      _.fields.filter(
        field =>
          field.type &&
          ((_.edit && !field.noEdit) || (!_.edit && !field.editOnly)) &&
          ((!_.external && !field.internal) ||
            (_.external && !field.external)) &&
          (!_.development || (_.development && !field.noDevelopment))
      )
    );

    const initialData = ref(_.rowData || {});
    const formData = ref(
      {
        ..._.rowData,
        isNotifiable:
          _.rowData.isNotifiable === undefined ? true : _.rowData.isNotifiable,
      } || {
        isNotifiable:
          _.rowData.isNotifiable === undefined ? true : _.rowData.isNotifiable,
      }
    );
    const updateData = val => {
      initialData.value = val;
      formData.value = { ...val };
      refs.dataform.resetValidation();
    };
    watch(
      () => _.rowData,
      val => {
        updateData(val);
      }
    );
    const checkChanges = () => {
      let changedRows = {};
      Object.keys(initialData.value).forEach(key => {
        if (initialData.value[key] != formData.value[key]) {
          changedRows[key] = formData.value[key];
        }
      });
      return Object.keys(changedRows).length ? changedRows : false;
    };

    const resetPassword = () => emit("reset-password", initialData.value.email);
    const resetConfirm = ref(false);

    const getRowIndex = () => {
      if (_.rows.length) {
        return _.rows.indexOf(initialData.value);
      }
    };
    const getNextRow = () => {
      const index = getRowIndex();
      return index < _.rows.length - 1 ? _.rows[index + 1] : _.rows[0];
    };
    const getPrevRow = () => {
      const index = getRowIndex();
      return index > 0 ? _.rows[index - 1] : _.rows[_.rows.length - 1];
    };

    const prevConfirm = ref(false);
    const prevEdit = isChangesSave => {
      if (isChangesSave) {
        save(false);
      }
      updateData(getPrevRow());
      prevConfirm.value = false;
    };

    const nextConfirm = ref(false);
    const nextEdit = isChangesSave => {
      if (isChangesSave) {
        save(false);
      }
      updateData(getNextRow());
      nextConfirm.value = false;
    };

    const close = () => {
      _.dialog.isOpen = false;
    };

    const save = (closeAfter, sendNotify) => {
      if (useFormValidation(inspectedFields.value)) {
        formData.value.isExternal = _.external;

        const data = _.edit ? checkChanges() || {} : formData.value;
        data.sendNotify = sendNotify;

        data.id = formData.value.id;
        data.role = _.role.role;

        if (_.role.role === "participant")
          switch (_.dialog.view) {
            case "createExternal": {
              data.assessmentPurpose = "HIRING";
              break;
            }
            case "createDevelopment": {
              data.assessmentPurpose = "DEVELOPMENT";
              break;
            }
            case "createPromotion": {
              data.assessmentPurpose = "PROMOTION";
              break;
            }
          }

        emit("save", data);
        if (closeAfter) {
          close();
        }
      } else {
        refs.dataform.validate();
      }
    };

    return {
      inspectedFields,
      formData,
      checkChanges,
      resetPassword,
      resetConfirm,
      prevConfirm,
      prevEdit,
      nextConfirm,
      nextEdit,
      close,
      save,
      registratedUser,
    };
  },
  components: {
    FormField,
  },
};
</script>

<style lang="scss" scoped>
.arrow {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  color: #afafaf;
  width: 70px;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  .v-icon {
    font-size: 18px;
    color: #afafaf !important;
  }
  &:hover {
    color: $mainColor;
    .v-icon {
      color: $mainColor !important;
    }
  }
}
</style>
